@import '../../../styles/variables';

.billing-stats {
    margin: 0 -5px;
    padding: 5px 0;

    &__label {
        margin-bottom: 5px;
        font-size: 0.75em;
        font-weight: 700;
        text-transform: uppercase;
    }

    &__value {
        font-size: 2.7em;
        font-weight: 700;
    }

    &__preloader {
        padding: 5px 0;
        display: flex;
        justify-content: space-around;
    }

    &__item {
        display: block;
        width: 100%;
        margin: 5px 0;
        padding: 15px 0;
        text-align: center;
        background-color: #fff;
        border-radius: $defaultBorderRadius;
        border: 1px solid $defaultBorderColor;
        cursor: pointer;

        &-wrapper {
            float: left;
            width: 50%;
            padding: 0 5px;
        }

        &--active {
            color: $highlightGreen;

            .billing-stats {
                &__preloader {
                    svg {
                        circle {
                            stroke: $highlightGreen;
                        }
                    }
                }
            }
        }

        &--expiring {
            color: $highlightOrange;

            .billing-stats {
                &__preloader {
                    svg {
                        circle {
                            stroke: $highlightOrange;
                        }
                    }
                }
            }
        }

        &--unpaid {
            color: $highlightRed;

            .billing-stats {
                &__preloader {
                    svg {
                        circle {
                            stroke: $highlightRed;
                        }
                    }
                }
            }
        }
    }
}