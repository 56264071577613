@import '../../../styles/variables';
@import '../../../styles/mixins';

.page-view {
    &__wrapper {
        position: relative;
    }

    &__main {
        margin-top: 36px;

        &--collapsed {
            .filter-groups {
                margin-right: -30px;
            }
        }
    }
}

.pagination {
    margin-top: 10px;
}

.users-link {
    font-size: 0.575rem;
    font-weight: 400;
    line-height: 1;
    padding: 1px 5px;
}

.instances {
    &__hint {
        min-width: 365px;
    }

    &__changed {
        color: $highlightOrange;

        &--negative {
            color: $highlightOrange;
        }

        &--positive {
            color: $highlightGreen;
        }
    }
}

.status {
    &--active {
        color: $highlightGreen;
    }

    &--expiring {
        color: $highlightPurple;
    }

    &--unpaid,
    &--blocked,
    &--acrhived {
        color: $highlightRed;
    }
}

.selected {
    margin: 0 -15px;

    @include clearfix;

    &__col {
        float: left;
        width: 33.333%;
        padding: 0 15px;

        &--wide {
            width: 41.666%;
        }

        &--action {
            width: 25%;
            font-size: 1rem;
            text-align: right;
        }
    }

    &__row {
        margin: 7px 0;
    }

    &__value {
        &--wide {
            width: 70%;
        }

        &--comment {
            -webkit-line-clamp: 5;
        }
    }

    &__name {
        &--wide {
            width: 30%;
        }
    }

    &__detailed {
        margin: 0 1rem;
        font-size: 0.75em;

        svg {
            fill: transparent;
        }
    }

    &__ban {
        display: inline-block;
        vertical-align: middle;

        svg {
            fill: transparent;
            stroke: $defaultGrayColor;
        }

        &--banned {
            svg {
                stroke: $highlightRed;
            }
        }
    }

    &__approve {
        padding-left: 15px;
        padding-right: 15px;

        svg {
            fill: transparent;
        }
    }

    &__email {
        color: $highlightBlue;
        font-size: 1em;
    }
}

.subscribe-dialog {
    padding: 36px 0;
    text-align: left;
}