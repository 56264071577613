.header {
    &__button {
        font-size: 0.75em;
    }

    &__icon {
        &--mortgage {
            margin-top: -4px;
        }
    }
}

.link {
    position: relative;
    display: block;
    padding: 5px 15px;
    color: #161c24;
    font-size: 0.75em;
    line-height: 1.2;
    white-space: nowrap;
    text-decoration: none;

    &__icon {
        display: inline-block;
        margin-right: 6px;
        vertical-align: bottom;

        svg {
            fill: transparent;
        }
    }
}

.mortgage {
    &-header {
        padding-right: 60px;
        padding-left: 60px;
        text-align: left;

        & > * {
            display: inline-block;
            vertical-align: middle;
        }
    }

    &-icon {
        margin-left: 15px;
    }

    &-dialog {
        display: block;
    }
}
