@import '../../../styles/variables';
@import '../../../styles/mixins';

.company-detailed {
    box-sizing: content-box;
    height: 80vh;
    min-height: 420px;
    max-height: 600px;
    padding-bottom: 1px;
    text-align: left;

    &__wrapper {
        display: inline-block;
        width: 76%;
        height: calc(100% - 48px);
        padding-bottom: 56px;
        vertical-align: top;
    }

    &__header {
        padding: 14px 15px 12px 15px;
        border-bottom: 1px solid $defaultBorderColor;
    }

    &__title {
        font-weight: 600;
        font-size: 1.125em;
        line-height: 1.2;
    }

    &__subscribe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: #fff;
        z-index: 10;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    &__cancel {
        margin-top: 12px;
    }
}

.footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    padding: 12px 12px 12px 18px;
    white-space: nowrap;
    background-color: #fff;
    border-top: 1px solid $defaultBorderColor;

    &__button {
        padding: 9px 12px;

        &:first-child {
            margin-right: 8px;
        }
    }

    &__buttons {
        opacity: 0;
        transition: opacity $baseTransitionTime;
        pointer-events: none;

        &--active {
            opacity: 1;
            pointer-events: auto;
        }
    }

    &__company {
        color: $defaultGrayColor;
        font-weight: 500;
        font-size: 0.625em;
        line-height: 1.4;

        & > div {
            display: inline-block;
            vertical-align: middle;
        }

        svg {
            margin-left: 10px;
            fill: transparent;
            stroke: $defaultGrayColor;
        }

        &--blocked {
            svg {
                stroke: $highlightRed;
            }
        }
    }

    &__col {
        display: inline-block;
        width: 50%;

        &:last-child {
            text-align: right;
        }
    }
}

.field {
    margin: 0 0 16px;

    &-label {
        margin: 0;
        font-size: 0.75em;
        line-height: 2;
    }

    &--name {
        max-width: 200px;
    }

    &--address {
        max-width: 50%;
    }
}

.fieldgroup {
    display: block;
    padding-right: 50px;

    &-list {
        margin: 0 -8px;

        @include clearfix;

        .field {
            float: left;
            width: 33.333%;
            padding: 0 8px;

            &--ownerType {
                width: 330px;
            }

            &--onADeposit {
                padding-left: 96px;
            }
        }
    }

    &--billingCompanyAddress {
        .fieldgroup {
            &-list {
                margin: 0;

                .field {
                    float: none;
                    width: auto;
                    padding: 0;
                }
            }
        }
    }
}

.main-info {
    display: flex;
    justify-content: space-between;
    padding-right: 50px;

    & > * {
        min-width: 145px;

        &:first-child {
            width: 60%;
        }
    }

    &__action {
        margin-bottom: 8px;

        &:first-child {
            margin-bottom: 32px;
        }

        & svg {
            stroke: $highlightRed;
            fill: #fff;
        }

        &-list {
            padding-top: 25px;
        }
    }
}

.subscription {
    display: flex;
    justify-content: space-between;
    padding-right: 50px;
    padding-bottom: 50px;

    &__form {
        width: 240px;
    }

    &__action-list {
        width: 145px;
    }

    &__field {
        margin-bottom: 16px;
    }

    &__label {
        margin-bottom: 5px;
        font-size: 0.75em;
        font-weight: 700;
    }

    &__value {
        font-size: 0.75em;

        &--active {
            color: $highlightGreen;
        }
    
        &--expiring {
            color: $highlightPurple;
        }
    
        &--unpaid,
        &--blocked,
        &--acrhived {
            color: $highlightRed;
        }
    }

    &__action {
        margin: 8px 0;

        &--subscribe {
            & svg {
                fill: #fff;
            }
        }
    }
}