@import '../../../styles/variables';

.root {
    // For correct display multiple tabs popup -->
    position: relative;
    min-height: 100vh;
    // For correct display multiple tabs popup -->
    overflow: hidden;
    background-color: $defaultContentBackground;

    &__header {
        position: relative;
        background-color: $defaultBackground;

        &::after {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            height: 1px;
            background-color: $defaultBorderColor;
            content: '';
        }
    }
}

.menu {
    position: relative;
    z-index: 5;
    float: left;
    margin-right: 10px;

    &__content {
        padding: 5px 0;

        &--user {
            padding: 30px 15px;
        }
    }

    &__link {
        position: relative;
        display: block;
        padding: 5px 15px;
        color: $defaultColor;
        font-size: 0.86em;
        line-height: 1.2;
        white-space: nowrap;
        text-decoration: none;

        &--group {
            padding: 8px 30px 8px 15px;

            :global(.select__menu) {
                position: relative;
                border-radius: unset;
                box-shadow: unset;
            }

            .menu__link-select {
                position: absolute;
                top: -5px;
                right: -251px;
                display: none;
                box-sizing: border-box;
                width: 250px;
                padding: 10px 20px;
                background-color: #fff;
                border: 1px solid $defaultBorderColor;
                border-radius: $defaultBorderRadius;
                box-shadow: 0 0 8px rgba(19, 25, 28, 0.1);

                &__title {
                    margin-bottom: 4px;
                    color: $defaultColor;
                    font-weight: normal;
                    font-size: 0.85em;
                    line-height: 1.17;
                }

                &__icon {
                    position: absolute;
                    left: 10px;

                    svg {
                        fill: $highlightBlue;
                    }
                }

                :global(.select__indicators) {
                    width: 0;
                    padding: 0;
                }

                :global(.select__value-container) {
                    padding-left: 35px;
                }
            }

            .menu__link {
                padding: 8px 10px 8px 0;
                overflow: hidden;
                font-size: 1.34em;
                text-overflow: ellipsis;
            }

            &:hover {
                background-color: #d0ecfc;

                .menu__link-select {
                    display: block;
                }
            }

            &::after {
                position: absolute;
                top: 50%;
                right: 15px;
                width: 0;
                height: 0;
                border-top: 3px solid transparent;
                border-bottom: 3px solid transparent;
                border-left: 6px solid #15a0ef;
                transform: translateY(-50%);
                content: '';
            }
        }
    }

    &__item {
        padding: 7px 10px;
        font-weight: 500;
        font-size: 0.86em;
        border-top: unset;
        border-radius: 0;

        &--main {
            padding: 7px 25px 7px 10px;
            color: #fff;
            background-color: $inputBorderFocus;
            border-bottom: unset;

            &::after {
                top: 50%;
                right: 10px;
                left: auto;
                width: 0;
                height: 0;
                margin: -1px 0 0 0;
                border-color: #fff transparent transparent transparent;
                border-style: solid;
                border-width: 4px 4px 0 4px;
                opacity: 1;
            }

            &:hover {
                border-color: $inputBorderFocus;
            }

            &.menu {
                &__item {
                    &--open {
                        background-color: $inputBorderFocus;
                        border-color: $inputBorderFocus;

                        &::after {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }

        &--user {
            padding-right: 25px;

            &::after {
                top: 50%;
                right: 10px;
                left: auto;
                width: 0;
                height: 0;
                margin: -1px 0 0 0;
                border-color: $inputBorderFocus transparent transparent transparent;
                border-style: solid;
                border-width: 4px 4px 0 4px;
                opacity: 1;
            }

            &.menu {
                &__item {
                    &--open {
                        &::after {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }
    }

    &__icon {
        display: inline-block;
        margin-right: 10px;
        vertical-align: bottom;

        svg {
            fill: transparent;
        }
    }

    &--main {
        font-size: 1em;

        .menu {
            &__link {
                padding: 5px 18px;
                font-weight: 500;
                font-size: 0.86em;
            }
        }
    }

    &--user,
    &--rate,
    &--notify {
        float: right;
    }

    &--user {
        margin-right: 0;
    }

    &--notify {
        margin-right: 0;
    }
}
