@import '../../../styles/variables';
@import '../../../styles/mixins';

.page-view {
    &__wrapper {
        position: relative;
    }
}

.filter {
    &__footer {
        position: static;
        z-index: 1;
        padding: 10px 20px 20px 20px;
        background-color: $defaultBackground;
        border-bottom: 1px solid $inputBorder;
    }

    &__field-list {
        position: relative;
        z-index: 2;
        height: auto;
        min-height: calc(100vh - 196px);
        padding: 8px 20px 10px 20px;
        will-change: transform;
    }
}

.operator-detailed {
    &__dialog {
        display: block;
    }
}

.selected {
    margin: 0 -15px;

    @include clearfix;

    &__col {
        float: left;
        width: 27.5%;
        padding: 0 15px;

        &--action {
            width: 45%;
            padding-top: 10px;
            font-size: 1rem;
            text-align: right;
        }
    }

    .selected {
        &__phone {
            color: $highlightBlue;
            font-size: 1em;
        }
    }
}

.pagination {
    margin-top: 10px;
}
