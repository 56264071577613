.activation-key {
    padding: 10px 0 0 0;
    text-align: left;

    &__field {
        margin-bottom: 16px;
    }

    &__label {
        font-size: 0.75em;
    }

    &__submit {
        margin-top: 32px;
    }
}
