.subscribe {
    position: relative;
    z-index: 1;
    width: 260px;
    margin: 0 auto;

    &__submit {
        margin-top: 16px;
    }
}

.header {
    font-size: 1.5em;
    font-weight: 700;
    margin-bottom: 1em;
    text-align: center;
}

.field {
    margin-bottom: 8px;

    &__label {
        font-size: 0.75em;
    }

    &__amount {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 -2%;

        & > * {
            width: 48%;
            margin: 0 2%;
        }
    }

    &__days {
        font-size: 0.75em;
    }

    :global(.select__value-container) {
        max-width: none;
    }
}
