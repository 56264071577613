@import '../../styles/mixins';
@import '../../styles/variables';

.pagination {
    display: block;
    text-align: center;

    &__list {
        display: inline-block;
        margin: 0;
        padding: 0;
        list-style-type: none;

        @include clearfix;
    }

    &__link {
        display: block;
        min-width: 30px;
        padding: 3px;
        overflow: hidden;
        color: inherit;
        font-size: 0.875em;
        line-height: 1.35;
        white-space: nowrap;
        background-color: $paginationBackground;
        border: 1px solid $paginationBorder;
        border-right-width: 0;
        cursor: pointer;
        transition:
            color $baseTransitionTime,
            border-color $baseTransitionTime,
            background-color $baseTransitionTime;
    }

    &__icon {
        display: inline-block;
        margin-bottom: 1px;
        transition: fill $baseTransitionTime;
        fill: inherit;
    }

    &__item {
        float: left;

        &:first-child {
            .pagination {
                &__link {
                    border-radius: $defaultBorderRadius 0 0 $defaultBorderRadius;
                }
            }
        }

        &:last-child {
            .pagination {
                &__link {
                    border-right-width: 1px;
                    border-radius: 0 $defaultBorderRadius $defaultBorderRadius 0;
                }
            }
        }

        &--active,
        &:hover {
            /* stylelint-disable-next-line no-descending-specificity */
            .pagination {
                /* stylelint-disable-next-line no-descending-specificity */
                &__link {
                    color: $paginationBackground;
                    background-color: $paginationActive;
                    border-color: $paginationActive;
                }

                &__icon {
                    fill: $paginationBackground;
                }
            }

            & + .pagination__item {
                .pagination {
                    &__link {
                        border-left-color: $paginationActive;
                    }
                }
            }
        }

        &--active {
            /* stylelint-disable-next-line no-descending-specificity */
            .pagination {
                &__link {
                    pointer-events: none;
                }
            }
        }

        &--start,
        &--left {
            .pagination {
                /* stylelint-disable-next-line no-descending-specificity */
                &__icon {
                    transform: rotate(180deg);
                }
            }
        }
    }
}
