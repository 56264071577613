@import '../../../styles/variables';
@import '../../../styles/mixins';

.page-view {
    &__wrapper {
        position: relative;
    }
}

.filter {
    &__footer {
        position: static;
        z-index: 1;
        padding: 10px 20px 20px 20px;
        background-color: $defaultBackground;
        border-bottom: 1px solid $inputBorder;
    }

    &__field-list {
        position: relative;
        z-index: 2;
        height: auto;
        min-height: calc(100vh - 196px);
        padding: 8px 20px 10px 20px;
        will-change: transform;
    }
}

.pagination {
    margin-top: 10px;
}
