@import '../../../styles/variables';
@import '../../../styles/mixins';

.page-view {
    &__wrapper {
        position: relative;
    }
}

.filter {
    &__footer {
        position: static;
        z-index: 1;
        padding: 10px 20px 20px 20px;
        background-color: $defaultBackground;
        border-bottom: 1px solid $inputBorder;
    }

    &__field-list {
        position: relative;
        z-index: 2;
        padding: 8px 20px 10px 20px;
        will-change: transform;
    }
}

.key-status {
    font-size: 1rem;

    &__label {
        font-weight: 600;
        font-size: 0.625em;
    }

    &__field-list {
        position: relative;

        &--disabled {
            &::after {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                content: '';
            }
        }
    }

    label[for="tag-status-BLOCKED"] {
        &:hover {
            color: $highlightRed;
            background-color: $rowOddBackground;
            border-color: $inputBorder;
        }
    }

    label[for="tag-status-ACTIVE"] {
        &:hover {
            color: $highlightGreen;
            background-color: $rowOddBackground;
            border-color: $inputBorder;
        }
    }

    input:checked + label[for="tag-status-BLOCKED"] {
        color: $defaultBackground;
        background-color: $highlightRed;
        border-color: $highlightRed;

        &:hover {
            color: $defaultBackground;
            background-color: $highlightRed;
            border-color: $highlightRed;
        }
    }

    input:checked + label[for="tag-status-ACTIVE"] {
        color: $defaultBackground;
        background-color: $highlightGreen;
        border-color: $highlightGreen;

        &:hover {
            color: $defaultBackground;
            background-color: $highlightGreen;
            border-color: $highlightGreen;
        }
    }
}

.status-trigger {
    display: block;
    font-weight: 600;

    &--active {
        color: $highlightGreen;
    }

    &--blocked {
        color: $highlightRed;
    }
}

.pagination {
    margin-top: 10px;
}

.new-activation-key {
    margin-top: -1px;
    padding: 0 20px 40px 20px;

    &__header {
        padding: 0 50px;
    }

    &__description {
        margin-top: 5px;
        font-weight: normal;
        font-size: 0.5em;
    }

    &__value {
        margin-top: 5px;
        font-weight: 700;
        font-size: 1.5em;
    }

    &__created {
        font-size: 0.75em;
    }

    &__submit {
        margin-top: 35px;
    }
}
