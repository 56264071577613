@import '../../../styles/variables';

.login {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &__wrapper {
        width: 424px;
        margin: 0 auto;
    }

    &__decorate {
        display: block;

        &--roof {
            height: 145px;
            background-image: url('./images/login-form-roof.png');
            background-position: 50% 0%;
        }

        &--base {
            height: 15px;
            background-image: url('./images/login-form-base.png');
            background-position: 1px 100%;
        }

        &--main {
            position: relative;
            padding: 0 75px 65px 75px;
            background-image: url('./images/login-form-main.png');
            background-position: 50% 50%;

            &::before,
            &::after {
                position: absolute;
                right: 0;
                left: 0;
                height: 3px;
                background-image: url('./images/login-form-main.png');
                content: '';
            }

            &::before {
                top: -1px;
            }

            &::after {
                bottom: -1px;
            }
        }
    }

    &__header {
        margin-bottom: 32px;
        font-weight: 600;
        font-size: 1.5em;
        text-align: center;
        text-transform: uppercase;
    }

    &__description {
        margin-top: 10px;
        font-weight: normal;
        font-size: 0.5em;
        text-transform: none;
    }

    &__body {
        min-height: 84px;
    }

    &__field {
        margin-bottom: 8px;
    }

    &__submit {
        margin-top: 24px;
    }

    &__forgot {
        margin: 8px 0 0 0;
        text-align: center;
    }

    &__footer {
        margin-top: 20px;
        font-size: 1rem;
        text-align: center;
    }
}

.container {
    height: 100vh;
    min-height: 610px;
    background-image: url('./images/login-bg.png');
    background-position: center center;
    background-size: cover;
}

.tooltip {
    float: right;
    width: 1.56em;
    margin-top: 0.365em;
    font-size: 0.6875em;
    line-height: 1.4545;
    text-align: center;
    color: $highlightBlue;
    border: 1px solid $defaultBorderColor;
    border-radius: 100%;

    &__content {
        z-index: 1;
        max-width: 200px;
        margin-top: -10px !important;
        padding: 3px 6px !important;
        color: $defaultColor !important;
        font-size: 0.625em !important;
        line-height: 1.4;
        background: $defaultBackground !important;
        border-radius: $defaultBorderRadius;
        box-shadow: 0 0 4px rgba(69, 109, 121, 0.25);

        :global(.multi-line) {
            text-align: left !important;
        }

        &::before,
        &::after {
            content: unset !important;
        }
    }
}
