@import '../../../styles/variables';
@import '../../../styles/mixins';

.page-view {
    &__wrapper {
        position: relative;
    }
}

.pagination {
    margin-top: 10px;
}

.full-filed-list {
    min-height: calc(100vh - 196px);
}