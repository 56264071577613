@import '../../../styles/variables';
@import '../../../styles/mixins';

.page-view {
    &__wrapper {
        position: relative;
    }
}

.filter {
    &__footer {
        position: static;
        z-index: 1;
        padding: 10px 20px 20px 20px;
        background-color: $defaultBackground;
        border-bottom: 1px solid $inputBorder;
    }

    &__field-list {
        position: relative;
        z-index: 2;
        height: auto;
        min-height: calc(100vh - 196px);
        padding: 8px 20px 10px 20px;
        will-change: transform;
    }
}

.hint {
    z-index: 1;
    width: 140px;
    margin-top: -10px !important;
    padding: 3px 6px !important;
    color: $defaultColor !important;
    font-size: 0.625em !important;
    line-height: 1.4;
    background: $defaultBackground !important;
    border-radius: $defaultBorderRadius;
    box-shadow: 0 0 4px rgba(69, 109, 121, 0.25);

    &::before,
    &::after {
        content: unset !important;
    }
}

.done,
.plan {
    font-weight: 600;
}

.done {
    color: $highlightRed;

    &--success {
        color: $highlightGreen;
    }
}

.plan {
    color: $highlightGreen;
}

.day-details {
    display: block;

    &__row {
        @include clearfix;

        &--header {
            margin-bottom: 3px;
        }
    }

    &__title {
        font-weight: 600;
    }

    &__name {
        float: left;
        width: 60%;
    }

    &__value {
        margin-left: 60%;
        text-align: right;
    }
}

.pagination {
    margin-top: 10px;
}
