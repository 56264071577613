@import '../../../styles/variables';
@import '../../../styles/mixins';


.page-view {
    &__wrapper {
        position: relative;
    }

    &__main {
        margin-top: 36px;

        &--collapsed {
            .filter-groups {
                margin-right: -30px;
            }
        }
    }
}

.pagination {
    margin-top: 10px;
}

.hr {
    margin: 7px -20px;
    height: 1px;
    background-color: $defaultBorderColor;
}

.selected {
    margin: 0 -15px;

    @include clearfix;

    &__col {
        float: left;
        width: 33.333%;
        padding: 0 15px;

        &--action {
            width:  33.333%;
            padding-top: 7px;
            font-size: 1rem;
        }

        &--name {
            width: 28%;
        }

        &--contacts {
            width: 38.666%;
        }

        &--company-name {
            width: 28%;
            padding-top: 7px;
        }
    }

    &__row {
        margin: 7px 0;
    }

    &__value {
       width: 60%;

       &--name {
           width: 80%
       }
    }

    &__name {
       width: 40%;

       &--name {
           width: 20%
       }
    }

    &__detailed {
        margin: 0 1rem;
        font-size: 0.75em;

        svg {
            fill: transparent;
        }
    }

    &__ban {
        display: inline-block;
        vertical-align: middle;

        svg {
            fill: transparent;
            stroke: $defaultGrayColor;
        }

        &--banned {
            svg {
                stroke: $highlightRed;
            }
        }
    }

    &__approve,
    &__promise {
        padding-left: 15px;
        padding-right: 15px;
    }

    &__promise {
        margin-left: 1rem;
    }

    &__approve {
        svg {
            fill: transparent;
        }
    }

    &__email {
        color: $highlightBlue;
        font-size: 1em;
    }

    &__phone {
        color: $highlightBlue;
        font-size: 1em;
    }
}

.company-dialog {
    padding: 36px 0;
    text-align: left;
}