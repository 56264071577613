@import '../../../styles/variables';
@import '../../../styles/mixins';

.operator-detailed {
    box-sizing: content-box;
    height: 80vh;
    min-height: 420px;
    max-height: 600px;
    padding-bottom: 1px;
    text-align: left;

    &__wrapper {
        display: inline-block;
        width: 76%;
        height: calc(100% - 80px);
        padding-bottom: 56px;
        vertical-align: top;
    }

    &__header {
        padding: 18px 15px 19px 15px;
        border-bottom: 1px solid $defaultBorderColor;
    }

    &__icon {
        float: left;
        margin-top: 2px;
        padding: 9px 7px 9px 10px;
        border: 1px solid $defaultBorderColor;
        border-radius: 100%;
    }

    &__title {
        margin-left: 55px;
        font-weight: 600;
        font-size: 1.125em;
        line-height: 1.2;
    }
}

.footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    padding: 12px 12px 12px 18px;
    white-space: nowrap;
    background-color: #fff;
    border-top: 1px solid $defaultBorderColor;

    &__button {
        padding: 9px 12px;

        &:first-child {
            margin-right: 8px;
        }
    }

    &__buttons {
        opacity: 0;
        transition: opacity $baseTransitionTime;
        pointer-events: none;

        &--active {
            opacity: 1;
            pointer-events: auto;
        }
    }

    &__operator {
        color: $defaultGrayColor;
        font-weight: 500;
        font-size: 0.625em;
        line-height: 1.4;

        & > div {
            display: inline-block;
            vertical-align: middle;
        }

        svg {
            margin-left: 10px;
            fill: transparent;
            stroke: $defaultGrayColor;
        }

        &--blocked {
            svg {
                stroke: $highlightRed;
            }
        }
    }

    &__col {
        display: inline-block;
        width: 50%;

        &:last-child {
            text-align: right;
        }
    }
}
