@import '../../../styles/variables';
@import '../../../styles/mixins';

.page-view {
    &__wrapper {
        position: relative;
    }
}

.pagination {
    margin-top: 10px;
}
