@import '../../../styles/variables';
@import '../../../styles/mixins';

.filter-groups {
    &__item {
        display: inline-block;
        margin-right: 20px;

        &:last-child {
            margin-right: 0;
        }
    }
}

.filter {
    &__footer {
        position: static;
        z-index: 1;
        padding: 10px 20px 20px 20px;
        background-color: $defaultBackground;
        border-bottom: 1px solid $inputBorder;
    }

    &__field-list {
        position: relative;
        z-index: 2;
        height: auto;
        min-height: 300px;
        padding: 8px 20px 10px 20px;
        will-change: transform;
    }

    &__field {
        display: block;
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }

        :global(.select__value-container) {
            max-width: 200px;
        }
    }

    &__label {
        font-size: 0.75em;
    }
}

.selected {
    margin: 0 -15px;

    @include clearfix;

    &__col {
        float: left;
        width: 27.5%;
        padding: 0 15px;

        &--action {
            width: 45%;
            padding-top: 10px;
            font-size: 1rem;
            text-align: right;
        }
    }

    .selected {
        &__phone {
            color: $highlightBlue;
            font-size: 1em;
        }
    }
}
